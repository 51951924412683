var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sm-editable-list',{attrs:{"controllerName":_vm.controllerName,"breadcrumbs":_vm.breadcrumbs,"tableCaption":_vm.tableCaption,"tableHeaders":_vm.tableHeaders},scopedSlots:_vm._u([{key:"accountId",fn:function(ref){
var row = ref.row;
return [_c('router-link',{staticClass:"editable-item__link",attrs:{"to":{ name: 'AccountEdit', params: { id: row.accountId } }}},[_vm._v(" "+_vm._s(row.accountId)+" ")])]}},{key:"basePhonesId",fn:function(ref){
var row = ref.row;
return [_c('router-link',{staticClass:"editable-item__link",attrs:{"to":{
        name: 'BasePhoneMatchingEdit',
        params: { id: row.basePhonesId },
      }}},[_vm._v(" "+_vm._s(row.basePhonesId)+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }